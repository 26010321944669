// import sayHello from './lib/sayHello.js';
import './lib/common.js';
import maskPlugin from 'jquery-mask-plugin'; // Маска телефона
import fancybox from '@fancyapps/fancybox'; // Модальные окна
import Rellax from 'rellax';

// sayHello();



$('[data-fancybox]').fancybox();

var rangeSlider = function() {
  var slider = $('label[range]'),
    range = $('input[type=range]'),
    value = $('.range-value');
		
  slider.each(function() {

    value.each(function() {
      var value = $(this).next().attr('value');
      $(this).html(value);
    });

    range.on('input', function() {
      $(this).prev(value).html(this.value);
    });
  });
};

rangeSlider();

$('.show-faq-link').on('click', function(e) {
  e.preventDefault();
  $('.faq').addClass('shown');
  $(this).remove();
});

$('[start]').on('click', function(e) {
  e.preventDefault();
  $('.start').toggleClass('start__shown');
  $('body').toggleClass('start__is_shown');
});


$('[data-payment]').on('click', function(e) {
  console.log(e);
  let method = $(this).data('payment'),
    price = $(this).data('price'),
    service = $(this).data('service'),
    doc = $(this).data('doc');
  $('#' + method + ' .service_name').html(service);
  $('#' + method + ' .price').val(price);
  $('#' + method + ' .service').val(service);
  $('#' + method + ' .service_doc').attr('data-src',doc);
  $.fancybox.open( $('#' + method), {
    touch: false
  });
  e.preventDefault();
});

// Utm
// This script added utm hidden input in form 
var params = window.location.search.replace('?','').split('&').reduce(
  function(p,e) {
    var a = e.split('=');
    p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
    return p;
  }, {});

$('form').each(function() { 
  let th = $(this).find('.hidden');
  $.each(params, function(key, value) {
    th.append('<input name="'+key+'" value="'+value+'" hidden>');
  });
});

$('#liqpay form').submit(function() {
  var th = $(this);
  $.ajax({
    type: 'POST',
    url: '/assets/static/_payment/liqpay/makeform.php',
    data: th.serialize()
  }).done(function(data) {
    $('#form_responce').html(data);
    $('#form_responce form').submit();
  });
  return false;
});

$('#payparts form').submit(function() {
  var th = $(this);
  $.ajax({
    type: 'POST',
    url: '/assets/static/_payment/payparts/makeform.php',
    data: th.serialize()
  }).done(function(data) {
    window.location.href = data;
  });
  return false;
});

$('#copy').click(function() {
  var temp = $('<input>'),
    text = $('.card span').text();
  $('body').append(temp);
  temp.val(text).select();
  document.execCommand('copy');
  temp.remove();

});



var rellax = new Rellax('.rellax');

// Вставка в форму комментария от нажатой кнопки
$('.button[href="#form"]').on('click', function() {
  let product = $(this).data('product');
  $('form .hidden input[name="product"]').remove();
  $('form .hidden').append('<input type="hidden" name="product" value="'+product+'">');
});

// Маска телефона, если телефон не заполнен до конца,
// то при нажатии куда либо сбрасывает введенное в инпут
let maskPhone = '+38 000 000-00-00';
$('input[name=phone]').mask(maskPhone).focusout(function() {
  let p = $(this);
  if (p.val().length < maskPhone.length) {
    p.val('');
  }
});

// Отработка формы, проверка на пустые поля 
var params = window.location.search.replace('?','').split('&').reduce(
  function(p,e) {
    var a = e.split('=');
    p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
    return p;
  }, {});

$('form').each(function() {
  let th = $(this).append('<div class="utm" hidden>').find('.utm');
  $.each(params, function(key, value) {
    if (key !== 0) {
      th.append('<input name="'+key+'" value="'+value+'" hidden>');
    }
  });
});

$(function() {
  $('form').each(function() {
    // Объявляем переменные (форма и кнопка отправки)
    let form = $(this),
      btn = form.find('.button-submit');

    // Добавляем каждому проверяемому полю, указание что поле пустое
    form.find('.field-input').addClass('empty-field');

    // Функция проверки полей формы
    function checkInput() {
      form.find('.field-input').each(function() {
        if($(this).val() !== '') {
          // Если поле не пустое удаляем класс-указание
          $(this).removeClass('empty-field');
        } else {
          // Если поле пустое добавляем класс-указание
          $(this).addClass('empty-field');
        }
      });
    }

    // Функция подсветки незаполненных полей
    function lightEmpty() {
      form.find('.empty-field').parent().addClass('empty-field');
      // Через полсекунды удаляем подсветку
      setTimeout(function() {
        form.find('.empty-field').parent().removeClass('empty-field');
      },1000);
    }

    // Функция отправки формы 
    // Нужно написать ajax функцию отправки формы
    function fromSubmit() {
      var phone = form.find('input[name=phone]').val();
      btn.prop('disabled', true);
      form.submit();

      $(document).on('af_complete', function(event, response) {

        var form = response.form;

        form.trigger('reset');
        btn.prop('disabled', false);
        
        $.fancybox.close();

        $.fancybox.open({
          src  : '#thanks',
          type : 'inline',
          buttons: [
            'close'
          ],
          afterLoad: function() {
            $('.check-phone').empty().append(phone);
          }
        });

        // Отслеживание лидов
        dataLayer.push({'event':'send_request'});
        fbq('track','Lead');
        // yaCounter34842275.reachGoal('lead_form_order');
      });

    }

    // Проверка в режиме реального времени
    setInterval(function() {
      // Запускаем функцию проверки полей на заполненность
      checkInput();
      // Считаем к-во незаполненных полей
      let sizeEmpty = form.find('.empty-field').length;

      // Вешаем условие-тригер на кнопку отправки формы
      if(sizeEmpty > 0) {
        if(btn.hasClass('disabled')) {
          return false;
        } else {
          btn.addClass('disabled');
        }
      } else {
        btn.removeClass('disabled');
      }
    },500);

    // Событие клика по кнопке отправить
    btn.click(function() {
      if($(this).hasClass('disabled')) {
        // подсвечиваем незаполненные поля и форму не отправляем, если есть незаполненные поля
        lightEmpty();
        return false;
      } else {
        // Все хорошо, все заполнено, отправляем форму
        fromSubmit();
      }
    });
  });
});

// Карусель
var mySwiper = new Swiper('.case-swiper', {
  init: true,
  speed: 400,
  spaceBetween: 20,
  freeMode: true,
  slidesPerView: 1,
  parallax: true,
  lazy: true,
  scrollbar: {
    el: '.swiper-scrollbar',
  },
  breakpoints: {
    600: {
      slidesPerView: 1
    },
    768: {
      slidesPerView: 2
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 20
    }
  }
});


// Click Tracking
$('[data-click]').on('click', function() {
  let click = $(this).data('click');
  fbq('trackCustom',click);
  yaCounter54652267.reachGoal(click);
});
